.input-wrapper {
  .input-group {
    display: flex;
    align-items: center;
  }

  .redirect-uris {
    display: flex;
    align-items: center;
    width: 100%;
    flex-wrap: nowrap;
    margin-bottom: 10px;

    .input-wrapper {
      flex-grow: 1;
    }

    input {
      max-width: none;
    }
  }

  .generate-button {
    margin-left: 10px;
  }
}

.auth-configuration-content {
  overflow: auto;
  max-height: 70vh;
  height: 100%;

  .error {
    font-size: 13px;
    margin-top: 5px;
    font-style: italic;
    color: var(--red-error);
  }

  .input-config {
    margin-top: 10px;
  }
}

.client-secret {
  display: flex;
  align-items: center;

  .input-wrapper {
    flex-grow: 1;
  }

  input {
    max-width: none;
  }
}

.auth-configuration-modal {
  width: 500px;

  .button-container {
    display: flex;
    width: 100%;
    justify-content: end;
  }
}
