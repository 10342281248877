.client-configuration-content {
  overflow: auto;
  max-height: 70vh;
  height: 100%;

  .error {
    font-size: 13px;
    margin-top: 5px;
    font-style: italic;
    color: var(--red-error);
  }

  .input-config {
    margin-top: 10px;
  }
}

.client-secret {
  display: flex;
  align-items: center;

  .input-wrapper {
    flex-grow: 1;
  }

  input {
    max-width: none;
  }
}

.client-configuration-modal {
  width: 500px;

  .button-container {
    display: flex;
    width: 100%;
    justify-content: end;
  }
  .date-wrapper {
    color: var(--grey);
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
  }
}
